import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout/Layout"
import { PrismicRichText } from "@prismicio/react"

const TermsPage = ({ data }) => {
  const prismicHomepage = data.allPrismicTerms.edges.slice(0, 1).pop().node.data
  return (
    <Layout>
      <section className="pt-16 md:pt-16">
        <div className="container mx-auto px-8">
          <PrismicRichText field={prismicHomepage.body.richText} />
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query TermsQuery {
    allPrismicTerms {
      edges {
        node {
          data {
            body {
              richText
            }
          }
        }
      }
    }
  }
`

export default TermsPage
